.saleassist-meeting-lite {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  background: #f0f0f0;
  place-content: center;
  place-items: center;
  overflow: hidden;

  .no-active-call-placeholder {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    gap: 15px;
    color: #959595;

    svg {
      font-size: 150px;
    }

    p {
      margin: 0;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      place-items: center;
      font-weight: 500;

      span {
        display: block;
      }
    }

    .go-home-button {
      color: #fff;
      padding: 8px 16px;
      width: 70px;
      border-radius: 5px;
      font-weight: 600;
      cursor: pointer;
      background: #3ac47d;
      margin-top: 15px;
      text-decoration: none;
      text-align: center;
      font-size: 14px;
    }
  }

  .sa-video-call-action-button {
    font-size: 22px;
  }
}

.saleassist-meeting-lite:has(#videoCallContainer) {
  background: #141414;
  z-index: 1;
}

.saleassist-meeting-lite:has(#videoCallContainer.live-stream) {
  background: #fff;
}

.sa-active-request-modal-title {
  display: flex;
  place-content: space-between;
  place-items: center;
  text-transform: capitalize;

  svg {
    font-size: 24px;
    color: #9e9e9e;
    cursor: pointer;
  }
}

.sa-active-request-modal-widget-content {
  position: relative;
  display: flex;
  font-size: 14px;

  span:nth-child(1) {
    position: absolute;
    top: 0px;
    left: 5px;
    font-weight: 500;
    background: -webkit-linear-gradient(top, #fff 50%, #f7f7f7 50%);
    color: #333;
    padding: 0 5px;
  }

  span:nth-child(2) {
    display: flex;
    padding: 12.5px 10px;
    margin: 10px 0 15px 0;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    width: 100%;
    background: #f7f7f7;
    overflow-x: auto;

    svg {
      display: flex;
      position: absolute;
      bottom: 18px;
      right: 2px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }
}

.sa-active-request-modal-user-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px;
}

.sa-active-request-modal-user-content {
  display: flex;
  font-size: 14px;

  span {
    flex: 0.5;
  }

  span:nth-child(1) {
    font-weight: 500;
    color: #333;
  }
}

.saleassist-meeting-lite-loader-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  place-content: center;
  place-items: center;
  color: #959595;
  gap: 20px;
  font-weight: 500;
  font-size: 18px;
}

.sa-agent-feedback-form {
  .sa-live-call-widget-lead-capture-form-group {
    padding-bottom: 16px;

    .sa-live-call-widget-lead-capture-form-field-label {
      color: #353535 !important;
      font-size: 15px !important;
      display: block;
      padding-bottom: 8px;
      width: 100%;
      margin: 0;

      .sa-form-input-required {
        color: #dc3545;
      }
    }

    .sa-live-call-widget-lead-capture-form-field {
      padding: 6px 12px;
      border-radius: 5px;
      width: 100%;
      outline: none;
      border: 1px solid #ddd;
      position: relative;
      background: #fff;
      color: #101010 !important;
      box-sizing: border-box;
    }
  }

  .sa-agent-feedback-form-error-text {
    font-size: 12px;
    font-weight: 500;
    color: #dc3545;
    text-align: center;
  }

  .sa-agent-feedback-form-submit-button {
    display: block;
    width: 100%;
    background-color: #3ac47d;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    outline: none;
    border: none !important;
    border-radius: 6px;
    cursor: pointer;
  }
}
