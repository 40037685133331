.lead-conversations-container {
  background: #fff;
  padding-bottom: 72px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .lead-conversations-container-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 16px 10px;
    border-bottom: 1px solid #ccc;

    .lead-avatar-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      color: #fff;
      font-weight: 700;
      position: relative;

      .lead-avatar-text {
        margin: 0;
        font-size: 18px;
      }

      .lead-avatar-status {
        width: 12px;
        height: 12px;
        bottom: 0;
        right: 1px;
        z-index: 1;
        position: absolute;
        border-radius: 100%;
        box-sizing: border-box;
      }
    }

    .lead-unique-field {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 0;
      font-weight: 800;
      color: #6d6d6d;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      box-sizing: border-box;

      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .lead-conversations-container-header.whatsapp-lead-detail {
    padding: 7px 10px;

    .lead-user-number {
      flex: 1;
    }
  }

  .lead-conversations-container-body {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .conversations-wrapper {
      height: calc(100% - 75px);
      overflow-x: hidden;
      overflow-y: auto;
    }

    .conversation-created-on {
      text-align: center;
      font-size: 12px;
      position: relative;

      .conversation-created-on-text {
        padding: 5px 20px;
        color: rgba(0, 0, 0, 0.5);
        background: #fff;
        margin: auto;
        position: relative;
        z-index: 1;
      }

      .conversation-created-on-divider {
        position: absolute;
        width: 60%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .lead-last-cta-click {
    text-align: start;
    padding: 0px 12px;
    font-size: 10px;
    height: 15px;
    color: rgba(0, 0, 0, 0.7);
  }
}

//Lead status and type dropdowns css
@media only screen and (max-width: 600px) {
   .lead-status-type-dropdown {
    // div label {
    //   display: none;
    // }
    div div .MuiSelect-select{
      padding: 3px 5px;
      // padding-right: 32px;
    }
  //   div div fieldset{
  //   border-width: 0px;    
  // }
  }
}