.msg-div {
  margin: 15px 0;
  display: flex;
  flex-direction: column;

  .rce-container-mbox {
    position: relative;
    max-width: 85%;
  }

  .rce-container-mbox.whatsapp_message {
    .rce-mbox {
      background: #0a7165;
      color: #fff;
    }

    .rce-mbox-right {
      background: #dcf8c6;
      color: #000;
    }
  }

  .rce-container-mbox.whatsapp_message,
  .rce-container-mbox.document_message {
    .rce-mbox-right {
      .rce-mbox-text:has(.chat-file-download-icon) {
        left: -50px;
        right: unset;
      }
    }

    .rce-mbox-text:has(.chat-file-download-icon) {
      position: absolute;
      right: -50px;
      top: 50%;
      transform: translateY(-50%);

      .chat-file-download-icon {
        width: 32px;
        height: 32px;
        display: flex;
        place-items: center;
        place-content: center;
        font-size: 20px;
        background: #deddeb;
        color: #83828e;
        border-radius: 50%;
        border: 1px solid #83828e;
        cursor: pointer;
      }
    }

    .rce-mbox-photo--img {
      display: flex;
      width: 250px;
      max-height: unset;

      img {
        width: 100%;
        height: 100%;
        min-height: unset;
        border-radius: 5px;
      }
    }

    .rce-mbox-video--video {
      display: flex;
      width: 250px;
      max-height: unset;

      video {
        border-radius: 5px;
      }
    }

    .rce-mbox-file {
      padding-bottom: 0;

      .rce-mbox-file--text {
        padding-left: 2px;
        display: flex;
        gap: 20px;

        .chat-file-title-text {
          font-size: 12.5px;
          display: flex;
          flex-direction: column;
          text-transform: uppercase;
          place-items: flex-start;
          place-content: center;
          font-family: "Roboto", Arial, sans-serif;

          span {
            color: #777;
          }
        }

        .chat-file-download-icon {
          width: 32px;
          height: 32px;
          display: flex;
          place-items: center;
          place-content: center;
          font-size: 20px;
          background: #deddeb;
          color: #83828e;
          border-radius: 50%;
          border: 1px solid #83828e;
          cursor: pointer;
        }
      }

      .rce-mbox-file--buttons {
        padding: 0;
      }
    }
  }

  .rce-container-mbox .rce-mbox {
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px solid rgb(221, 221, 221);
  }

  .rce-mbox-right {
    background: #ebf2f9;
  }

  .msg-div-info {
    padding: 0px 5px;
    font-size: 12px;
    background: #fff;
    display: inline-block;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 5px;

    svg {
      vertical-align: middle;
    }
  }

  //follow-up-notes
  .follow-up-notes__background div {
    background-color: #efe8a7;
  }
  .lead_conversion_tracking_msg div {       //this is to show the msg if lead status or type or conversion status has been changed
    background-color: #3AC47D;
    color: white;
  }

  .lead_conversion_tracking_msg div div div {   //purchase tag and sale conversion text was not alligned properly, so given this styling
    display: flex;
    align-items: center;
    column-gap: 6px;
}

  .lead_status_type_tracking_msg div {       //this is to show the msg if lead status or type or conversion status has been changed
    background-color: orange;
    color: white;
  }
}

.rce-mbox-file > button > * {
  padding: 0 10px;
}

.rce-mbox-file--icon {
  font-size: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.rce-mbox-file > button {
  background: #e9e9e9;
  display: flex;
  border-radius: 5px;
  margin-top: -3px;
  margin-right: -6px;
  margin-left: -6px;
  align-items: center;
  min-height: 52px;
  max-width: 500px;
  padding: 5px 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  border: none;
}

.rce-mbox-photo,
.rce-mbox-video {
  margin-top: -3px;
  margin-right: -6px;
  margin-left: -6px;
}

.document_message_wrapper {
  max-width: 85%;
  background: #ebf2f9;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 6px;

  .rce-container-mbox {
    max-width: unset;

    .rce-mbox {
      border: none;
    }

    .rce-mbox-right {
      background: transparent;
    }
  }
}

.document_message_wrapper.multiple-document {
  // background: red;

  .rce-container-mbox {
    .rce-mbox-file {
      button {
        width: 250px;
      }

      .rce-mbox-file--text {
        flex: 1;

        .chat-file-title-text {
          flex: 1;
        }
      }
    }

    .rce-mbox-video--video,
    .rce-mbox-photo--img,
    .rce-mbox-file button {
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
}

tr.page-title-subheading {
  font-size: 10px;
  text-transform: capitalize;
  border-bottom: 1px solid #ddd;
}
tr.page-title-subheading td {
  padding: 10px 0px;
  max-width: 100px;
  color: #495057;
}
.modal-footer {
  background: rgb(248, 249, 250);
  padding: 10px 15px;
  border-top: 1px solid rgb(153, 153, 153);
  text-align: right;
}
.modal-footer button {
  background: #545cd8;
  border: 1px solid #545cd8;
  color: #fff;
  padding: 7px 15px;
  border-radius: 3px;
  font-size: 10px;
}


