.header {
  padding: 12px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.m-0 {
  margin: 0;
}
