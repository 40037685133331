/* Style for the progress bar */
.saleassist-lite-meeting-progress-bar {
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
}

/* Animation for the progress bar */
.saleassist-lite-meeting-progress-bar::after {
  content: "";
  display: block;
  width: 80%;
  height: 100%;
  background-color: #3498db;
  position: absolute;
  animation: progress-animation 1.25s linear infinite;
}

/* Keyframes for the animation */
@keyframes progress-animation {
  0% {
    left: -80%;
  }
  100% {
    left: 100%;
  }
}
