@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");

body {
  font-family: "Roboto", Arial, sans-serif;
  margin: 0;
  padding: 0;
}

#videoCallContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  position: relative;
  flex: 1;
  overflow: hidden;
}

#videoContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  background-color: #fff;
  overflow: hidden;
  border-radius: inherit;
}

#videoCallMainScreen {
  flex: 1;
}

#videoCallMainScreen div {
  width: 100%;
  height: 100%;
}

#videoCallVideoTiles {
  position: absolute;
  left: 0;
  top: 33px;
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;
  height: calc(100% - 53px);
  z-index: 1;
}

.video-call-video-tiles-transition:has(.video-call-wrapper) {
  width: 130px;
  padding: 8px;
  transition: width 0.3s ease;
}

.video-call-video-tiles-transition-expand:has(.video-call-wrapper) {
  width: 0px !important;
  padding: 0px;
  transition: width 0.3s ease, padding 0.5s ease;
}

.video-call-wrapper {
  position: relative;
  width: 114px;
  height: 88px;
  margin-bottom: 10px;
  background: #000;

  video {
    object-fit: contain !important;
  }
}

.video-call-wrapper:last-child {
  margin-bottom: 0px;
}

#videoCallVideoTiles .video-call-wrapper {
  border-radius: 16px;
  border: 2px solid rgb(125, 125, 125);
  overflow: hidden;
}

#videoCallVideoTiles .video-call-wrapper div {
  border-radius: 16px;
}

#remoteUserPlaceholder:nth-child(5n + 1) {
  .speaking-wave-effect {
    background: rgba(230, 126, 34, 0.3);
  }
  .user-display-name {
    background-color: rgb(230, 126, 34);
    box-shadow: rgb(230, 126, 34) 0px 0px 20px;
  }
}

#remoteUserPlaceholder:nth-child(5n + 2) {
  .speaking-wave-effect {
    background: rgba(241, 196, 15, 0.3);
  }
  .user-display-name {
    background-color: rgb(241, 196, 15);
    box-shadow: rgb(241, 196, 15) 0px 0px 20px;
  }
}

#remoteUserPlaceholder:nth-child(5n + 3) {
  .speaking-wave-effect {
    background: rgba(155, 89, 182, 0.3);
  }
  .user-display-name {
    background-color: rgb(155, 89, 182);
    box-shadow: rgb(155, 89, 182) 0px 0px 20px;
  }
}

#remoteUserPlaceholder:nth-child(5n + 4) {
  .speaking-wave-effect {
    background: rgba(231, 76, 60, 0.3);
  }
  .user-display-name {
    background-color: rgb(231, 76, 60);
    box-shadow: rgb(231, 76, 60) 0px 0px 20px;
  }
}

#remoteUserPlaceholder:nth-child(5n + 5) {
  .speaking-wave-effect {
    background: rgba(30, 144, 255, 0.3);
  }
  .user-display-name {
    background-color: rgb(30, 144, 255);
    box-shadow: rgb(30, 144, 255) 0px 0px 20px;
  }
}

#videoCallMainScreen #localUserPlaceholder {
  font-size: 30px;

  .user-display-name {
    width: 90px;
    height: 90px;
  }
}

#videoCallMainScreen #remoteUserPlaceholder {
  width: 90px;
  height: 90px;
  font-size: 30px;
}

#localUserPlaceholder,
#remoteUserPlaceholder {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  place-content: center;
  place-items: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;

  .speaking-wave-effect {
    background: rgba(30, 144, 255, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    transition: all 1s;
    border-radius: 50%;
  }

  .user-display-name {
    width: 100%;
    height: 100%;
    display: flex;
    place-content: center;
    place-items: center;
    border-radius: 50%;
    background-color: rgb(30, 144, 255);
    box-shadow: rgb(30, 144, 255) 0px 0px 20px;
    z-index: 1;
  }
}

#localUserPlaceholder {
  .user-display-name {
    width: 50px;
    height: 50px;
  }
}

#remoteUserPlaceholder {
  display: flex;
}

#localVideoContainer #localUserPlaceholder {
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: inherit;
  z-index: 1;
}

#videoCallVideoTiles .speaking-wave-effect {
  max-width: 80px;
  max-height: 80px;
}

video {
  flex: 1;
  max-width: 100%;
  max-height: 100%;
}

#saleassist-video-call-controls {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.control-button {
  position: relative;
  border: none;
  background-color: rgb(63, 63, 65);
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  height: 35px;
  width: 40px;
  display: flex;
  place-content: center;
  place-items: center;
}

#flipCamera {
  display: none;
}

#screenShare {
  display: none;
}

#leaveButton {
  background-color: rgb(235, 39, 30);
}

.videoCallVideoTilesToggleBtn {
  position: absolute;
  display: none;
  place-content: center;
  place-items: center;
  height: 25px;
  width: 25px;
  border-radius: 8px;
  filter: drop-shadow(1px 2px 2px rgb(0 0 0 / 0.6));
  background: rgba(255, 255, 255, 0.3);
  color: white;
  font-size: 20px;
  top: 8px;
  left: 8px;
  z-index: 2;
  cursor: pointer;

  svg {
    margin-top: 3.5px;
    transform: rotate(0deg);
  }
}

#videoCallVideoTiles:has(.video-call-wrapper) ~ .videoCallVideoTilesToggleBtn {
  display: flex;
}

.transform-videoCallVideoTilesToggleBtn {
  svg {
    transform: rotate(180deg);
  }
}

.mic-status-indicator {
  position: absolute;
  bottom: 4px;
  right: 34px;
  display: flex;
  font-size: 16px;
  color: white;
  padding: 2px 2px 2px 6px;
  border-radius: 5px 0px 0px 5px;
  background: rgba(255, 255, 255, 0.3);
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.6));
  z-index: 1;
}

.mic-status-indicator::before {
  content: attr(user-name);
  font-size: 10px;
  line-height: 16px;
  margin-right: 4px;
  font-weight: 500;
}

.mic-status-indicator #mic-off {
  color: red;
}

#videoCallVideoTiles .mic-status-indicator::before {
  display: none;
}

#videoCallVideoTiles .mic-status-indicator {
  top: 4px;
  right: 4px;
  bottom: unset;
  padding: 2px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.2);
}

.network-quality-info {
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 24px;
  height: 20px;
  display: flex;
  place-items: center;
  padding-left: 4px;
  border-radius: 0 5px 5px 0px;
  color: #999;
  background: rgba(255, 255, 255, 0.3);
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.6));
  z-index: 1;
}

#videoCallVideoTiles .network-quality-info svg {
  display: none;
}

#videoCallVideoTiles .network-quality-info {
  top: 6px;
  left: 6px;
  bottom: unset;
  right: unset;
  padding: 2px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.2);
  width: unset;
  height: unset;
}

#videoCallVideoTiles .network-quality-info::after {
  content: "";
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 50%;
}

.username-indicator {
  display: none;
  position: absolute;
  bottom: 3px;
  left: 4px;
  color: #fff;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  padding: 0px 6px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 7.5px);
  direction: ltr;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.6));
  box-sizing: border-box;
  z-index: 1;
}

#videoCallVideoTiles .username-indicator {
  display: block;
}

.pip-mode-btn {
  position: absolute;
  top: 28px;
  right: 4px;
  display: none;
  font-size: 16px;
  color: white;
  padding: 2px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.2);
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.6));
  z-index: 1;
  cursor: pointer;
}

#videoCallVideoTiles .pip-mode-btn {
  display: flex;
}

.volume-indicator {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 25px;
  height: 25px;
  display: flex;
  place-content: center;
  place-items: center;
  gap: 3px;
  background: #1a73e8;
  border-radius: 8px;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.6));
  z-index: 1;

  .bars {
    background: #fff;
    width: 3px;
    height: 15%;
    border-radius: 3px;
  }

  #bar1,
  #bar3 {
    max-height: 30%;
  }

  #bar2 {
    max-height: 60%;
  }
}

#videoCallVideoTiles .volume-indicator {
  display: none;
}

#videoContainer:has(#videoCallVideoTiles .video-call-wrapper) {
  #videoCallMainScreen .volume-indicator {
    left: 41px;
  }
}

.url-copied-indication-popup,
.on-hold-indication-popup {
  position: absolute;
  display: flex;
  place-items: center;
  gap: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  transition: all 0.5s ease;
}

.url-copied-indication-popup {
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  height: 35px;
  place-content: center;
  background: green;
  border-radius: 8px;
  padding: 0px 12px;
  z-index: 1028;
}

.on-hold-indication-popup {
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  flex-direction: column;
  place-content: end;
  background: linear-gradient(to top, black, transparent);
  backdrop-filter: blur(4px);
  z-index: 1027;
}

.url-copied-indication-popup.url-copied-indication-popup-show {
  top: 8px;
  transition: all 0.5s ease;
}

.on-hold-indication-popup.on-hold-indication-popup-show {
  bottom: 0px;
  transition: all 0.5s ease-in-out;
}

.sa-video-call-top-bar {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  z-index: 1027;
}

.sa-video-call-top-bar-button {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.6));
}

.more-option-button {
  position: relative;
  border: none;
  background-color: rgb(63, 63, 65);
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  height: 35px;
  width: 20px;
  display: flex;
  place-content: center;
  place-items: center;

  svg {
    transform: rotate(90deg);
    position: absolute;
  }
}

.saleassist-video-call-more-options-control {
  position: absolute;
  bottom: 92px;
  left: 50%;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  background: rgb(63, 63, 65);
  border-radius: 8px;
  padding: 0px 10px;
  gap: 10px;
  transition: all 0.5s ease;
  display: flex;
  place-content: space-evenly;
  overflow: hidden;
  z-index: 1;

  button {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
  }
}

.more-options-control-active {
  width: 274px;
  height: 55px;
  transition: all 0.5s ease;

  button {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
    transition-delay: 0.3s;
  }
}

.more-control-button {
  display: flex;
  flex-direction: column;
  height: 55px;
  width: 56px;
  gap: 3px;
  font-size: 20px;
  position: relative;
}

.more-control-helper-text {
  font-size: 10px;
  text-wrap: nowrap;
}

#inputDeviceSettings,
#virtualBackgroundWindow,
.sa-video-call-popup-panel-container {
  position: absolute;
  background: radial-gradient(circle 18px at top right, #0000 95%, #3f3f41) top
    right;
  width: 260px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 0 25px 0;
  border-radius: 18px;
  display: none;
  flex-direction: column;
  gap: 15px;
  z-index: 1027;
  user-select: none;
}

.sa-video-call-popup-panel-container {
  width: 300px;
  display: flex;
  background: radial-gradient(
      circle 18px at top right,
      rgba(0, 0, 0, 0) 95%,
      rgb(255 255 255 / 80%)
    )
    top right;
  backdrop-filter: blur(20px);

  .content-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    gap: 10px;

    .helper-text {
      font-size: 12px;
      color: #555;
      line-height: 14px;
      padding: 0 2px;
    }

    .submit-button {
      width: 100%;
      border: none;
      border-radius: 0.25rem;
      background-color: #2196f3;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      padding: 12px;
      margin-top: 15px;
      cursor: pointer;
    }
  }
}

.input-device-settings-header,
.sa-video-call-popup-panel-container .header-container {
  position: relative;
  text-align: center;
  color: white;
  display: flex;
  place-items: center;
  place-content: center;
  margin-bottom: 8px;
  font-weight: bold;
}

.sa-video-call-popup-panel-container .header-container {
  font-size: 16px;
  color: #333;
}

#inputDeviceSettingsClose,
#virtualBackgroundWindowClose,
.sa-video-call-popup-panel-container .close-icon {
  position: absolute;
  right: -7.5px;
  top: -23px;
  display: flex;
  font-size: 14px;
  background: red;
  color: #fff;
  width: 20px;
  height: 20px;
  place-items: center;
  place-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.sa-video-call-popup-panel-filter-by-container {
  height: 56px;
  display: flex;
  padding: 2.75px 7px;
  margin: 0 0 15px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  border-width: thin;

  .title {
    padding: 0 4px;
    font-size: 12.5px;
    color: rgba(0, 0, 0, 0.6);
  }

  .MuiFormGroup-root {
    padding-left: 6px;
  }
}

.input-device-settings {
  color: #fff;
  display: flex;
  place-content: center;
  place-items: center;
  gap: 10px;

  span {
    display: flex;
    font-size: 22px;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    padding: 0.5em;
    font-size: 14px;
    color: #333;
    position: relative;
    display: inline-block;
    background-color: #f2f2f2;
    border-radius: 4px;
    width: 180px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='gray'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1em 0.5em;
    background-position: right center;
    background-clip: border-box;
    -moz-background-clip: border-box;
    -webkit-background-clip: border-box;
  }

  select::-ms-expand {
    display: none;
  }

  select:focus {
    outline: none;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  }

  select:hover {
    background-color: #e0e0e0;
  }
}

.virtual-background-window-header {
  position: relative;
  font-weight: bold;
  color: white;
  font-size: 14px;
  display: flex;
  place-content: space-around;
}

.virtual-background-window-tabs-cont {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  opacity: 0.2;
}

.virtual-background-window-tabs {
  background: #6d6d6d;
  padding: 5px;
  font-size: 12.5px;
  font-weight: bold;
  color: white;
  display: flex;
  place-content: space-around;

  span {
    margin-bottom: 0;
    padding: 1px 10px 0;
    border-radius: 5px;
    cursor: pointer;
  }
}

.virtual-background-window-tabs .active {
  background: #2196f3;
}

.active-virtual-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border: 4px solid #2196f3;
}

.video-background-blur-cont {
  padding: 0 20px;
  gap: 10px;
  display: none;

  .video-background-blur-btn {
    flex: 1;
    height: 60px;
    background: rgb(241, 243, 244);
    display: flex;
    place-content: center;
    place-items: center;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
  }
}

.video-background-color-cont,
.video-background-image-cont {
  display: none;
  padding: 0 20px;
  gap: 8px;
  flex-wrap: wrap;

  .video-background-color-btn,
  .video-background-color-custom-btn,
  .video-background-image-btn,
  .video-background-image-custom-btn {
    width: 49px;
    height: 49px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
  }

  .video-background-color-custom-btn {
    background: red;
  }

  .video-background-image-custom-btn {
    background: #ccc;
  }

  .video-background-color-icon,
  .video-background-image-icon {
    font-size: 22px;
    color: black;
    border-radius: 50%;
    background: #ccc;
    position: absolute;
    bottom: -4px;
    right: -4px;
    border: 3px solid #3f3f41;
    width: 28px;
    height: 28px;
    display: flex;
    place-content: center;
    place-items: center;
    z-index: 1;
  }
}

.video-background-image-icon {
  font-size: 16px !important;
}

.video-background-image-btn img,
.video-background-image-custom-btn img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.virtual-backgorund-window-tabs-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
}

// slider styling
.sa-video-call-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin-bottom: 0px;
}

.sa-video-call-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sa-video-call-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6d6d6d;
  border-radius: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sa-video-call-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sa-video-call-slider {
  background-color: #2196f3;
}

input:focus + .sa-video-call-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sa-video-call-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

// device access error styling
.sa-device-access-error-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1027;
  background: rgb(255 255 255 / 10%);
  backdrop-filter: blur(2px);
}

.sa-device-access-error-cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 310px;
  background: #2d2d2d;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.34) 0px 6px 10px 0px, rgba(0, 0, 0, 0.32) 0px 1px 18px 0px;
}

.sa-device-access-error-header {
  font-size: 16px;
  font-weight: bold;
  text-align: justify;
  color: #e5e5e5;
}

.sa-device-access-error-body {
  font-size: 14px;
  text-align: justify;
  color: #a5a5a5;

  ul {
    list-style-type: none;
    margin: 16px 0 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    background: #000;
    border-left: 2px solid #477bae;
  }

  li {
    .steps {
        font-size: 12px;
        color: #7f8c8d;
        display: block;
        margin-bottom: 5px;
    }

    .item {
        font-size: 14px;
        color: #477bae;

        svg {
            color: #7f8c8d;
            vertical-align: bottom;
        }

        .mobile-view {
            display: none;
        }

        @media screen and (max-width: 768px) {
            .mobile-view {
                display: inline;
            }
        }
    }
  }
}

.sa-device-access-error-footer {
  display: flex;
  place-content: flex-end;

  .sa-device-access-error-dismiss-btn {
    font-size: 14px;
    font-weight: bold;
    color: #eb271e;
    width: max-content;
    background: rgb(235 39 30 / 15%);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
  }
}

// chat box styling
.saleassist-video-call-chat-container {
  background: #141414;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999 !important;
}

#videoCallContainer.live-stream
  + .saleassist-video-call-chat-container.sa-chat-hidden,
.saleassist-video-call-chat-container.sa-chat-hidden {
  width: 0px;
  margin: 0px;
  opacity: 0;
  transition: opacity 0.2s ease, width 0.5s ease;
}

.saleassist-video-call-chat-container.sa-chat-active {
  width: 400px;
  // margin: 4px;
  opacity: 1;
  transition: width 0.5s ease, opacity 0.5s ease 0.2s;
}

// End code - ui fix for video call on call from widget.

.saleassist-video-call-chat-header {
  position: relative;
  color: #e1e1e1;
  display: flex;
  place-items: center;
  place-content: space-between;
  padding: 20px 24px;
  border-bottom: 1px solid;
  margin-bottom: 5px;

  span:nth-child(1) {
    font-size: 16px;
    font-weight: bold;
  }

  #saleassistVideoChatCloseButton {
    cursor: pointer;
    position: relative;
    font-size: 18px;

    svg {
      stroke-width: 30px;
    }
  }
}

.saleassist-video-call-chat-log {
  padding: 10px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.saleassist-video-call-chat-input-box {
  position: relative;
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.chat-input-overlay {
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 75%);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  place-content: center;
  place-items: center;
}

.saleassist-video-call-document-preview-container {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.sa-chat-document-preview {
  background: #e1e1e1;
  color: #595959;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  place-content: space-between;
  place-items: center;
  gap: 8px;

  .sa-chat-document-file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: normal;

    svg {
      vertical-align: text-top;
    }
  }

  .sa-chat-remove-document-button {
    background: #595959;
    border-radius: 50%;
    color: #e1e1e1;
    cursor: pointer;
    font-size: 16px;
    width: 20px;
    height: 20px;
    display: flex;
    place-content: center;
    place-items: center;

    svg {
      stroke-width: 30px;
    }
  }
}

.sa-chat-document-preview:last-child {
  margin-bottom: 10px;
}

.sa-chat-document-preview-sent {
  display: flex;
  place-content: center;
  place-items: center;
  gap: 5px;
  background: #3d3d3d;
  padding: 7px 14px;
  border-radius: 4px;
  margin: 4px 0;
  color: #fff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

  span:nth-child(1) {
    display: flex;
  }

  span:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.saleassist-video-call-chat-input-container {
  display: flex;
}

.saleassist-video-call-chat-input-wrapper {
  flex: 1;
  display: flex;
  color: white;
  border: none;
  border-radius: 6px;
  background: #3d3d3d;

  label {
    margin-bottom: 0px;
  }
}

#saleassistVideoChatMessageInput {
  flex: 1;
  color: #fff;
  padding: 10px 10px 10px 15px;
  border-radius: 20px;
  font-size: 16px;
  border: none;
  background: transparent;
  outline: none;
  line-height: normal;
}

#saleassistVideoChatMessageInput::placeholder {
  color: #ddd;
}

#saleassistVideoChatUploadButton,
#saleassistVideoChatScreenshotButton {
  height: 41px;
  display: flex;
  place-content: center;
  place-items: center;
  font-size: 22px;
  cursor: pointer;
  margin-right: 10px;
}

#saleassistVideoChatSendButton {
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 43px;
  font-size: 18px;

  svg {
    margin-left: 4px;
  }
}

.message {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  max-width: 80%;
  word-break: break-word;
  font-size: 16px;
}

.message-meta-info {
  padding: 0 5px;
  margin: 0 5px;
  font-size: 10.5px;
  color: #858585;
}

.sent {
  display: flex;
  flex-direction: column;
  place-items: flex-end;

  .message {
    background-color: #525252;
    color: #fff;
  }
}

.received {
  display: flex;
  flex-direction: column;
  place-items: flex-start;

  .message {
    background-color: #292929;
    color: #fff;
  }
}

// message notification
#sa-message .sa-message-notification {
  background: #08ff08;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  display: none;
  font-size: 10px;
  font-weight: bold;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  color: #000;
  place-content: center;
  place-items: center;
}

// live stream top bar
.sa-live-stream-info-bar {
  position: absolute;
  left: 8px;
  top: 8px;
  height: 35px;
  display: flex;
  place-content: center;
  place-items: center;
  padding-right: 10px;
  background: #e1e1e1;
  color: white;
  border-radius: 10px;
  z-index: 1025;
  overflow: hidden;
  gap: 10px;

  .sa-live-stream-live-button {
    background: #777672;
    padding: 0 10px;
    font-weight: 600;
    height: 100%;
    display: flex;
    place-items: center;
    place-content: center;
    cursor: pointer;
    line-height: normal;
  }

  .sa-live-stream-info {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 5px;
    color: #3f3f41;

    svg {
      font-size: 22px;
    }

    span {
      font-size: 18px;
      line-height: 16px;
      margin-top: 1px;
    }
  }
}

// responsiveness handling
@media screen and (min-width: 450px) {
  .video-call-wrapper {
    width: 154px;
    height: 118px;
  }
  .video-call-video-tiles-transition:has(.video-call-wrapper) {
    width: 170px;
  }

  .sa-device-access-error-cont {
    width: 400px;
    padding: 25px;
    gap: 20px;
  }

  .sa-device-access-error-header {
    font-size: 18px;
  }

  .sa-device-access-error-body,
  .sa-device-access-error-dismiss-btn {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .video-call-wrapper {
    width: 200px;
    height: 150px;
  }
  .video-call-video-tiles-transition:has(.video-call-wrapper) {
    width: 216px;
  }
}

@media screen and (max-width: 768px) {
  .saleassist-video-call-chat-container {
    position: absolute;
    border-radius: 0;
    z-index: 1025;
    bottom: 0;
  }

  #videoCallContainer.live-stream
    + .saleassist-video-call-chat-container.sa-chat-hidden,
  .saleassist-video-call-chat-container.sa-chat-hidden {
    width: 100%;
    height: 0px;
    transition: opacity 0.2s ease, height 0.5s ease;
  }

  #videoCallContainer.live-stream
    + .saleassist-video-call-chat-container.sa-chat-active,
  .saleassist-video-call-chat-container.sa-chat-active {
    height: 100%;
    width: 100%;
    margin: 0px;
    transition: height 0.5s ease, opacity 0.5s ease 0.2s;
  }

  #saleassistVideoChatMessageInput {
    font-size: 16px;
  }
}

// loader-page
.saleassist-video-call-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  display: none;
  place-content: center;
  place-items: center;
  flex-direction: column;
  gap: 25px;
  z-index: 2000;

  p {
    margin: 0;
    line-height: 22px;
    text-align: center;
  }

  .permission-info {
    margin: 10px;
    padding: 8px 24px;
    border-radius: 12px;
    font-size: 14px;
    background: #373737;
    color: orange;
  }
}

// live-stream layout styling
#videoCallContainer.live-stream {
  background: #fff;

  .sa-video-call-top-bar {
    position: static;
    flex-direction: row;
    place-content: end;
    padding: 8px;

    & > div {
      color: #3f3f41;
      background: #e1e1e1;
      filter: unset;
    }
  }

  #videoCallMainScreen {
    display: flex;
    flex-wrap: wrap;
    margin: 8px;
    gap: 8px;

    .video-call-wrapper {
      width: auto;
      height: auto;
      margin: 0;
      flex: 1 1 calc(50% - 8px);
      max-width: calc(50% - 4px);
      border-radius: 12px;

      & > div {
        border-radius: inherit;
      }
    }

    &:has(.video-call-wrapper:nth-child(1)) .video-call-wrapper {
      max-width: unset;
    }

    &:has(.video-call-wrapper:nth-child(2)) .video-call-wrapper {
      max-width: calc(50% - 4px);
    }

    &.sa-optimize--layout {
      &:has(.video-call-wrapper:nth-child(2)) .video-call-wrapper {
        flex: 1 1 calc(100% - 8px);
        max-width: calc(100% - 0px);
      }

      &:has(.video-call-wrapper:nth-child(3)) .video-call-wrapper {
        flex: 1 1 calc(50% - 8px);
        max-width: calc(50% - 4px);
      }
    }
  }

  .saleassist-video-call-more-options-control {
    bottom: 52px;
  }

  #saleassist-video-call-controls {
    position: static;
    background: #fff;
    padding: 8px;
    border-radius: 0;
    transform: unset;
  }
}

#videoCallContainer.live-stream {
  & + .saleassist-video-call-chat-container {
    background: #e1e1e1;
    height: calc(100% - 16px);
    margin: 8px 8px 8px 0;

    .saleassist-video-call-chat-header {
      color: #3f3f41;
      border-bottom: 2px solid #fff;
    }

    .sa-chat-document-preview {
      background: #ccc;
    }

    .saleassist-video-call-chat-input-wrapper {
      background: #fff;
      color: #3f3f41;

      #saleassistVideoChatMessageInput {
        color: inherit;
      }

      #saleassistVideoChatMessageInput::placeholder {
        color: #aaa;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #videoCallContainer.live-stream {
    #videoCallMainScreen {
      &:has(.video-call-wrapper:nth-child(2)) .video-call-wrapper {
        flex: 1 1 calc(100% - 8px);
        max-width: calc(100% - 0px);
      }

      &:has(.video-call-wrapper:nth-child(3)) .video-call-wrapper {
        flex: 1 1 calc(50% - 8px);
        max-width: calc(50% - 4px);
      }
    }
  }
}

@media screen and (min-width: 968px) {
  #videoCallContainer.live-stream {
    #videoCallMainScreen {
      // Applying different styles if more than 4 .video-call-wrapper elements
      &:has(.video-call-wrapper:nth-child(n + 5)) .video-call-wrapper {
        flex: 1 1 calc(25% - 8px);
        max-width: calc(25% - 6px);
      }
    }
  }
}

@keyframes screen-share-spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
