.saleassist-upcoming-meeting-lite {
  height: 100%;
  display: flex;
  place-content: center;
  place-items: center;

  .no-upcoming-meeting-placeholder {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    gap: 15px;
    color: #959595;

    svg {
      font-size: 100px;
    }

    p {
      margin: 0;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      place-items: center;
      font-weight: 500;
      background: -webkit-linear-gradient(right, #ccc 10%, #3ac47d);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      span {
        display: block;
      }
    }
  }
}

.upcoming-meeting-heading {
  color: #6d6d6d;
  text-align: center;
  font-size: 18px;
}

.calls-btn-container {
  margin: 24px 20px;
  border-radius: 6px;
  padding: 20px;

  .meeting-requests-btn {
    text-transform: initial;
    margin-right: 10px;
    background: #ccddff;
    color: #1b86fd;

    &:hover {
      background: #ccddff;
      color: #1b86fd;
    }
  }

  .missed-calls-btn {
    text-transform: initial;
    margin-right: 10px;
    background: #ffefdc;
    color: #ff9700;

    &:hover {
      background: #ffefdc;
      color: #ff9700;
    }
  }

  .call-requests-count-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fefefe;
    padding: 2px 4px;
    color: #000;
    border-radius: 9999px;
    display: inline-block;
    min-width: 24px;
    min-height: 24px;
    margin-left: 4px;
    font-weight: 700;
  }
}

@media screen and (max-width: 640px) {
  .calls-btn-container {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 12px 10px;

    .meeting-requests-btn,
    .missed-calls-btn {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .saleassist-upcoming-meeting-lite {
    .no-upcoming-meeting-placeholder {
      gap: 8px;

      svg {
        font-size: 50px;
      }

      p {
        font-size: 15px;
      }
    }
  }
}
