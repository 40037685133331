.saleassist-dashboard-utilities {
  overflow-y: auto;
  margin-bottom: 50px;

  .profile-container {
    position: relative;
    display: flex;
    place-content: space-around;
    margin-top: 18px;

    .profile-card {
      position: relative;
      background: #fff;
      width: 100%;
      max-width: 298px;
      height: 150px;
      display: flex;
      flex-direction: column;
      place-content: center;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
        rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

      .content-wrapper {
        position: relative;
        display: flex;
        place-items: center;
        gap: 25px;
        padding: 15px;

        .content-icon {
          display: flex;
          place-content: center;
          place-items: center;
          width: 62px;
          height: 62px;
          font-size: 42px;
          background: #e7e7e7;
          border-radius: 50%;
          border: 4px solid #fff;
          margin-left: 10px;
          z-index: 1;
        }

        .content-text {
          display: flex;
          flex-direction: column;
          gap: 4px;
          font-size: 16px;
          font-weight: 500;
          z-index: 1;

          span:last-child {
            display: block;
            color: #999;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
          }
        }

        .vector-shape {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 108px;
          height: 95px;
          border-radius: 0 50px 50px 0px;
          background: rgb(0 128 0 / 50%);

          &.busy {
            background: rgb(255 0 0 / 60%);
          }

          &.online {
            background: rgb(46 202 69 / 60%);
          }

          &.offline {
            background: #ddd;
          }
        }
      }

      .edit-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        display: flex;
        place-content: center;
        place-items: center;
        background: #efefef;
        border-radius: 50%;
        color: #555;
      }
    }
  }

  .utilities-container {
    margin: 30px;
    display: flex;
    place-content: center;
    gap: 30px;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      color: inherit;
    }

    .utility-card {
      display: flex;
      flex-direction: column;
      place-content: center;
      gap: 10px;
      width: 100px;
      height: 100px;
      border-radius: 20px;
      place-items: center;
      padding: 15px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
        rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

      .card-icon {
        width: 52px;
        height: 52px;
        display: flex;
        place-content: center;
        place-items: center;
        border-radius: 15px;

        .call-transfer-icon {
          path,
          g {
            fill: #fff;
          }
        }
      }

      .card-text {
        font-weight: 600;
        text-align: center;
      }

      &.history {
        background: #d5d0da;
        color: #5b5266;
        border: 2px solid #726680;

        .card-icon {
          font-size: 30px;
          background: #726680;
          color: #fff;
        }
      }

      &.call-transfer {
        background: #cfd8dc;
        color: #455a64;
        border: 2px solid #455a64;

        .card-icon {
          font-size: 28px;
          background: #455a64;
          color: #fff;
        }
      }

      &.active-requests {
        background: #cee2f3;
        color: #3f68a5;
        border: 2px solid #3f68a5;

        .card-icon {
          font-size: 24px;
          background: #3f68a5;
          color: #fff;
        }
      }

      &.missed-calls {
        background: #f1d4d7;
        color: #af4d4d;
        border: 2px solid #af4d4d;

        .card-icon {
          font-size: 24px;
          background: #af4d4d;
          color: #fff;
        }
      }
      &.to-do-tasks {
        background: #ff7f502e;
        color: #ff7f50;
        border: 2px solid #ff7f50;

        .card-icon {
          font-size: 24px;
          background: #ff7f50;
          color: #fff;
        }
      }
      &.accept-invitation {
        background: #cfd8dc;
        color: #37515e;
        border: 2px solid #667f8b;

        .card-icon {
          font-size: 28px;
          background: #516d7a;
          color: #fff;
        }
      }
    }
  }
}
