.toDoDateTimePicker {
    label {
        transform: translate(14px, 9px) scale(1);
        -webkit-transform: translate(14px, 9px) scale(1);
        // display: none;
    }
    div {
        height: 38px;

        input {
            padding: 0px;
            padding-left: 10px;
        }
    }
}
.toDoMainCard {
    box-shadow: 0 .46875rem 2.1875rem rgba(8, 10, 37, .03), 0 .9375rem 1.40625rem rgba(8, 10, 37, .03), 0 .25rem .53125rem rgba(8, 10, 37, .05), 0 .125rem .1875rem rgba(8, 10, 37, .03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    margin: 0px 10px;
    border-radius: 8px;
    // overflow-y: auto;
    margin-bottom: 75px;
    scrollbar-width: thin;
}

.updateBtn:hover {
    background-color: #545CD8 !important;
    color: white
}

.deleteBtn:hover {
    background-color: #D92550 !important;
    color: white
}
.form_fieldsRow {
    display: flex;
    width: 100%;
    align-items: center;
}
.form_fieldsRow>div:first-child {
    margin-right: 5px;
}
.remndr{width: 50%;}
.todoMainSection{
    max-height: 85vh;
    overflow: hidden;
    .todoMainList{
        overflow: hidden scroll;
        scrollbar-width: thin;
        max-height: 70vh;
    }
}

.addBtn {
    position: fixed;
    top: 68px;
    right: 30px;
}
.todoMainHeading{
    display: flex;
    justify-content: space-between;
    margin-top:20px;
    select{
        cursor: pointer;
        font-size: 18px;
        border: none;
        font-weight: 500;
        outline: none;
        background-color: transparent;
        color: #1565c0;
        padding: 3px 10px;
        border: 1px solid #1565c0;
        border-radius: 4px;
        option{
            span{
                width: 5px;
                height: 5px;
                display: inline-block;
            }
        }
    }
}
.donPopup {
    background: #00000082;
}
.donPopup .MuiMenu-paper {
    top: 40% !important;
}
.todoMainList {
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            display: flex;
            .listChckBox {
                padding: 2px 0px;
                span {
                    border: 1px solid #63c05b;
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                    border-radius: 50px;
                    background: #63c05ba3;
                }
            }
            .listItems {
                padding: 0px 5px 15px 5px;
                width: 100%;
                border-bottom: 1px solid #ddd;
                margin-bottom: 15px;
                 h5 {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    text-transform: capitalize;
                    margin-bottom: 5px;
                    font-weight: 400;
                    display: flex;
                    justify-content: space-between;
                    a {
                        text-decoration: none;
                        color: #63c05b;
                        font-size: 16px;
                        display: flex;
                        align-items: flex-end;
                    }
                }
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 15px;
                    color: #999;
                }
                .actionBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 5px;
                    span {
                        color: #de4a3c;
                        font-size: 14px;
                        display: inline-block;
                    }
                    .actionIcon{
                        display: flex;
                        align-items: center;
                            svg {
                                width: 20px;
                                height: 20px;
                                margin-left: 10px;
                            }
                    } 
                }
                
            }
        }
        li:first-child {
            margin-top: 20px;
        }
        li:last-child {
            .listItems {
                border: none;
                margin: 0;
                padding-bottom: 0;
            }
        }
    }
}