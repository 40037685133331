body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.saleassist-dashboard-lite {
  position: relative;
  background: #f0f0f0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.saleassist-dashboard-lite:has(.saleassist-meeting-lite #videoCallContainer)
  .header {
  display: none;
}

.saleassist-dashboard-lite:has(.saleassist-meeting-lite #videoCallContainer)
  .saleassist-footer-nav-lite {
  display: none;
}

.text-primary {
  color: #545cd8 !important;
}
