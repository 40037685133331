.saleassist-footer-nav-lite {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  border-radius: 15px;
  display: flex;
  gap: 65px;
  background: #fff;
  padding: 15px 0;
  font-size: 24px;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

  a {
    display: flex;
    text-decoration: none;
    color: #555;
  }

  .active {
    color: #007ed4;
  }

  .nav-items {
    display: flex;
    flex: 1;
    place-content: space-around;
  }

  .action-button {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 54px;
    height: 54px;
    border-radius: 18px;
    background: #fae34f;
    color: #333;
    display: flex;
    place-content: center;
    place-items: center;
    transition: top 0.25s ease;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

    svg {
      transform: rotate(0deg);
      transition: transform 0.25s ease 0.125s;
    }
  }

  .action-button.rotate {
    top: -27px;
    transition: top 0.25s ease;
  }

  .action-button.rotate svg {
    transform: rotate(45deg);
    transition: transform 0.25s ease 0.125s;
    top: -27px;
  }

  .notification {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 6px;
      background: red;
      border-radius: 50%;
    }
  }
}
