.chat-control-panel {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: -10px;
    z-index: 9;
    background: linear-gradient(#f7f9fa,#fbfdff);
    animation-duration: 1s;
    height: 50px;
    margin: 0px 15px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 1px 2px;
     ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0 10px;
      height: 100%;
      list-style: none;
      li button {
        background: #fff;
        border-radius: 5px;
        border: 1px solid #ddd;
        padding: 7px 15px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
         svg {
          width: 16px;
          height: 16px;
        }
      }
      li select {
        // background: #fff;
        border-radius: 5px;
        border: 1px solid #ddd;
        padding: 7px 15px; 
        outline: none;
      }
    }
  }
  .demo-multiple-checkbox-status-list {
    height: 35px !important;
  }
  .chat-control-panel.show {
    animation-name: fadeInPanel;
    animation-fill-mode: forwards; 
  }
  
  .chat-control-panel.hide {
    animation-name: fadeOutPanel;
    animation-fill-mode: forwards;  
  }
  
  @keyframes fadeInPanel {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOutPanel {
    0% {
      opacity: 1;
      transform: translateY(0);
      // display: none;
    }
    100% {
      opacity: 0;
      transform: translateY(100px);
    }
  }
  
  
  .chat-control-panel ul li:nth-child(1) button:hover {
    background: rgb(250 245 255);
    color: rgb(147 51 234);
  }
  .chat-control-panel ul li:nth-child(2) button:hover {
    background: rgb(239 246 255);
    color: rgb(37 99 235);
  }
  .chat-control-panel ul li:nth-child(3) button:hover {
    background: rgb(236 254 255);
    color: rgb(8 145 178);
  }
  .chat-control-panel ul li:nth-child(4) button:hover {
    background: rgb(240 253 250);
    color: rgb(13 148 136);
  }
  .chat-control-panel ul li:nth-child(5) button:hover {
    background: rgb(240 253 244);
    color: rgb(22 163 74);
  }
  .chat-control-panel ul li:nth-child(6) select option:hover{
    background: rgb(255 247 237);
    color: rgb(234 88 12);
  }
  .chat-control-panel ul li:nth-child(7) select option:hover {
    background: rgb(254 252 232);
    color: rgb(202 138 4);
  }
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    .chat-control-panel ul li button{
      padding: 3px 5px;
      font-size: 12px;
    }
    .chat-control-panel ul li select{
      padding: 3px 5px;
      font-size: 12px;
    }
    .chat-control-panel ul li button svg {
      width: 14px;
    }
    .demo-multiple-checkbox-status-list {
      height: 28px !important;
    }

  }
  
  @media screen and (max-width: 768px) {
    .chat-control-panel ul li button svg {
      width: 13px;
      height: 13px;
    }
    .chat-control-panel ul li button{
      padding: 8px;
    }
  }