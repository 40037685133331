// styling for date picker
.saleassist-missedCall-menu {
  .MuiMenuItem-root {
    gap: 12px;
  }

  .date-range-item {
    flex-direction: column;
    place-items: start;

    .date-picker-wrapper {
      display: flex;
      place-items: center;
      gap: 6px;

      .date-picker .MuiInputBase-root {
        padding-right: 8px;

        input {
          width: 68px;
          font-size: 12px;
          padding: 8px 0px 8px 8px;
        }
      }
    }
  }
}

// styling for UI
.saleassist-missedCall-lite {
  height: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
  overflow: auto;

  .missedCall-header {
    display: flex;
    place-content: space-between;
    place-items: center;
    width: calc(100% - 48px);
    padding: 4px 8px 4px 16px;
    border-radius: 8px;
    z-index: 1;

    .title {
      display: flex;
      place-content: center;
      place-items: center;
      gap: 8px;

      p {
        margin: 0px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .filter {
      button {
        font-size: 18px;
      }
    }
  }

  .missedCall-timeline {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: auto;

    .date-divider {
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      color: #000;
      display: flex;
      place-content: center;

      span {
        position: relative;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 600;
        background: #fff;
        border-radius: 50px;
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
          rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
      }
    }

    .time-info {
      position: relative;

      span {
        display: block;
        font-size: 9px;
        font-weight: 400;
      }
    }

    .meeting-card {
      position: relative;
      background: #fff;
      border-radius: 8px;
      height: 135px;
      margin: 5px 0;
      overflow: hidden;

      &.warning-color-card {
        background: rgb(237 108 2 / 10%);
      }

      .title {
        display: block;
        font-size: 12.5px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 8px 8px 4px 8px;
        background: #fff;
        border-bottom: 1px solid #ccc;
      }

      .description {
        font-size: 11px;
        padding: 4px 8px 8px 8px;

        p {
          margin: 0;
        }

        .follow-up-btn {
          position: absolute;
          bottom: 8px;
          right: 8px;
          text-transform: capitalize;
          font-size: 10px;
          padding: 4px;
          line-height: normal;
        }
      }
    }

    .load-more-button {
      display: flex;
      place-content: center;
      margin-bottom: 85px;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 375px) {
  .saleassist-missedCall-lite {
    .missedCall-timeline {
      ul {
        padding: 6px 10px;
      }
    }
  }
}