.swipe-container {
    width: 300px;
    height: 50px;
    margin-bottom: 15px;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 16px;
    font-weight: 400;
    background-color: #14a16d;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
}
  
.swipe-handle {
    width: 42px;
    height: 42px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    transition: left 0.3s ease;

    svg {
        path:nth-child(1) {
            color: #14a16d;
        }

        path:nth-child(2) {
            color: #14a16d73;
        }
    }
}
  
.swipe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    cursor: pointer;
}
  
.swipe-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, transparent, rgb(255 255 255 / 40%), transparent);
    transform: skew(340deg);
    animation: toFro 5s infinite ease-in-out;
    z-index: 0;
  }
  
@keyframes toFro {
    20%, 100% {
      opacity: 0;
      left: 100%;
    }
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
}