.sa-dashboard-lite-upcoming-schedule {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sa-dashboard-lite-custom-calendar {
  display: flex;
  flex-direction: column;
  height: 161px;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 20px 20px;

    .header-active-date {
      gap: 8px;
      display: flex;
      place-items: center;
      font-weight: 500;

      .day {
        border-radius: 12px;
        font-size: 24px;
        width: 45px;
        height: 45px;
        display: flex;
        place-content: center;
        place-items: center;
        background: #333;
        color: #fae34f;
      }

      .month-year {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 4px;
        font-weight: 500;
      }
    }

    .prev-next {
      font-size: 24px;
      display: flex;
      gap: 8px;
      place-items: center;

      span {
        background: #333;
        display: flex;
        height: max-content;
        color: #fae34f;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  table {
    margin: 0px 14px;
  }

  th {
    min-width: 40px;
  }

  td {
    position: relative;
    padding: 10px 0;
    box-sizing: border-box;
    border: 1px solid transparent;
    text-align: center;
    cursor: pointer;
    outline: none;

    .day-cell {
      padding: 10px;
      border-radius: 12px;
    }

    .notification-dot {
      position: absolute;
      bottom: 2.5px;
      left: 50%;
      transform: translateX(-50%);
      background: #ff9700;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
  }

  .fade {
    color: #999;
  }

  .active {
    font-weight: bold;
    color: #333;
    background: rgb(250 227 79 / 30%);
    border: 1px solid rgb(250 227 79 / 30%);
    border: 1px solid #f9db1d;
  }

  .today {
    border: 1px solid #999;
  }

  .active.today {
    border: 1px solid #f9db1d;
  }
}

.sa-dashboard-lite-custom-calendar.expanded {
  animation-name: rowOpenAnimation;
}
.sa-dashboard-lite-custom-calendar.closed {
  animation-name: rowCloseAnimation;
}

@keyframes rowOpenAnimation {
  from {
    height: 161px;
  }
  to {
    height: 357px;
  }
}

@keyframes rowCloseAnimation {
  from {
    min-height: 357px;
  }
  to {
    min-height: 161px;
  }
}

.sa-dashbaord-lite-upcoming-task-card {
  position: relative;
  width: 100%;
  margin-top: 15px;
  padding-top: 40px;
  background: #fff;
  border-radius: 35px 35px 0 0;
  height: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px -4px,
    rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;

  .toggle-card-button {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 0.5px;
    background: #333;
    border-radius: 10px;
    border: 2px solid #333;
    margin: 10px 0 20px 0;
    z-index: 1;
  }

  .no-meetings-found-container {
    height: 169px;
    display: grid;
    place-content: center;
    padding: 24px;
    padding-top: 0;

    .no-meetings-found-message {
      margin: 0;
    }
  }

  .list-content {
    height: 100%;
    overflow-y: auto;

    .meeting-card {
      position: relative;
      background: rgba(250, 227, 79, 0.2);
      color: #333;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: 0 25px 25px 25px;
      border: 2px solid #f9db1d;
      border-radius: 20px;
      padding-bottom: 10px;

      .card-header {
        display: flex;
        place-content: space-between;
        padding: 15px 15px 10px 15px;
        font-size: 16px;
        gap: 10px;

        .title {
          text-transform: capitalize;
          font-weight: 600;
          flex: 1;
        }

        .time {
          text-transform: uppercase;
        }
      }

      .description {
        font-size: 16px;
        padding: 0 15px;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      .time-des{
        font-size: 16px;
        padding: 0 15px;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        max-width: 250px;
      }
      .time {
        font-size: 16px;
        padding: 0 15px;
      }

      .action-buttons {
        display: flex;

        span {
          font-size: 18px;
          background: #333;
          color: #fff;
          width: 28px;
          height: 28px;
          display: flex;
          place-content: center;
          place-items: center;
          border-radius: 50%;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
            rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        }
      }
 .action-buttons-main{
          padding: 0 15px;
          margin-top: 15px;
          display: flex;
          gap: 10px;
        }
      .reschedule-button {
        position: absolute;
        bottom: 15px;
        right: 15px;
        background: #fff;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        place-content: center;
        place-items: center;
        font-size: 24px;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
          rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
      }
    }

    .meeting-card:last-child {
      margin-bottom: 94px;
    }
  }
}

.dashboard-meeting-details-quick-action-btn {
  padding: 2px 8px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
  background: green;
  color: #fff;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  cursor: pointer;
  height: fit-content;
}
.spin-animation-start {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 374px) {
  .sa-dashboard-lite-custom-calendar {
    .header {
      padding: 10px 10px 20px 10px;
    }

    table {
      margin: 0px 2px;
    }

    th {
      min-width: 38px;
      font-size: 14px;
    }

    td {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 768px) {
  .sa-dashboard-lite-upcoming-schedule {
    flex-direction: row;
    justify-content: center;
  }

  .sa-dashbaord-lite-upcoming-task-card .toggle-card-button {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .sa-dashboard-lite-custom-calendar {
    min-width: 500px;
  }

  .sa-dashbaord-lite-upcoming-task-card {
    max-width: 480px;
  }
}
