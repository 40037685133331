// emojis-wrapper
.dashboard-chat-input-emojis-wrapper {
  position: absolute;
  top: -65px;
  right: -15px;
  display: none;
  place-content: center;
  place-items: center;
  gap: 12px;
  font-size: 20px;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

  span {
    width: 25px;
    height: 24px;
    display: flex;
    place-content: center;
    place-items: center;
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
    }
  }
}

.dashboard-chat-input-emojis-wrapper.closed {
  display: none;
}

.dashboard-chat-input-emojis-wrapper.opened {
  display: flex;
}
