.meeting-container {
  margin: 20px;
  margin-bottom: 0;
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  overflow: auto;
  box-sizing: border-box;
  height: calc(100% - 150px);

  .meeting-title {
    font-size: 15px;
    font-weight: 700;
    color: #12154e;
    margin: 0;
    margin-bottom: 16px;
  }

  .label-text {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #101010c9;
  }

  .MuiRadio-root {
    padding: 5px;
  }

  .MuiFormControlLabel-label {
    font-size: 15px;
  }

  .meeting-name-description-container {
    display: flex;
    gap: 12px;

    .meeting-name-wrapper,
    .meeting-description-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
      padding: 6px 12px;
    }
  }

  .registration-form-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin: 12px 0;
  }
}

@media screen and (max-width: 768px) {
  .meeting-name-description-container {
    flex-direction: column;
  }
}
