.saleassist-callTransfer-lite {
  height: 100%;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: auto;

  .logs-timeline {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: auto;

    .date-divider {
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      color: #000;
      display: flex;
      place-content: center;

      span {
        position: relative;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 600;
        background: #fff;
        border-radius: 50px;
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
          rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
      }
    }

    .time-info {
      position: relative;

      span {
        display: block;
        font-size: 9px;
        font-weight: 400;
      }
    }

    .meeting-card {
      background: #fff;
      border-radius: 8px;
      height: 135px;
      margin: 5px 0;
      overflow: hidden;

      &.success-color-card {
        background: rgb(46 125 50 / 10%);
      }

      &.warning-color-card {
        background: rgb(237 108 2 / 10%);
      }

      &.error-color-card {
        background: rgb(211 47 47 / 10%);
      }

      &.primary-color-card {
        background: rgb(25 118 210 / 10%);
      }

      .title {
        display: block;
        font-size: 12.5px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 8px 8px 4px 8px;
        background: #fff;
        border-bottom: 1px solid #ccc;
      }

      .description {
        font-size: 13px;
        padding: 4px 8px 8px 8px;

        p {
          margin: 0;
        }

        .text-color-danger {
          color: #c62828;
          font-weight: 600;
          margin-right: 4px;
        }

        .text-color-primary {
          color: #1565c0;
          font-weight: 600;
          margin-right: 4px;
        }
      }
    }

    .call-trasnfer-icon {
      path,
      g {
        fill: #fff;
      }
    }

    .load-more-button {
      display: flex;
      place-content: center;
      margin-bottom: 85px;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 375px) {
  .saleassist-callTransfer-lite {
    .logs-timeline {
      ul {
        padding: 6px 10px;
      }
    }
  }
}
