.saleassist-login-error {
  height: 100%;
  display: flex;
  place-content: center;
  place-items: center;

  .login-error-placeholder {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    gap: 15px;
    color: #959595;

    svg {
      font-size: 100px;
      color: red;
    }

    p {
      margin: 0;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      place-items: center;
      font-weight: 500;

      span {
        display: block;
      }
    }
  }
}
