.saleassist-action-overlay-container {
  position: fixed;
  top: 0;
  background: rgb(0 0 0 / 60%);
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 999;
  animation: fadein 0.75s forwards;

  .quick-action-button-layout {
    width: 80%;
    height: 164px;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .quick-action-button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    place-items: center;
    position: absolute;

    &.pos1 {
      bottom: 0;
      left: 0;
    }

    &.pos2 {
      top: 0;
      left: 32%;
      transform: translateX(-32%);
    }

    &.pos3 {
      top: 0;
      right: 32%;
      transform: translateX(32%);
    }

    &.pos4 {
      bottom: 0;
      right: 0;
    }

    .quick-action-button {
      padding: 2px;
      border: 2px solid #fff;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;

      span {
        font-size: 24px;
        background: #fff;
        width: 42px;
        height: 42px;
        display: flex;
        place-content: center;
        place-items: center;
        padding: 5px;
        border-radius: 15px;
      }
    }
  }

  .quick-action-button-text {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
