.profile {
  margin: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 6px;
}

.profile .MuiInput-root {
  width: 100% !important;
  max-width: 480px;
}
