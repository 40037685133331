.saleassist-outbound-calls-container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;

  .input-wrapper {
    background: #f0f0f0;
    flex: 0.35;
    display: flex;
    place-content: flex-end;
    flex-direction: column;

    .input-text-container {
      display: flex;
      place-content: center;
      place-items: center;
      padding: 5px 10px;
      gap: 15px;

      input {
        flex: 1;
        border: none;
        background: transparent;
        font-size: 24px;
        font-weight: 600;
        text-align: end;
      }

      .input-clear {
        font-size: 28px;
        display: flex;
        height: 30px;
      }

      .input-paste {
        font-size: 24px;
        display: flex;
      }
    }
  }

  .dialer-wrapper {
    flex: 0.65;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    padding: 10px 35px;
    margin-bottom: 85px;

    .dialer-button {
      border: 2px solid #ebebeb;
      border-radius: 50%;
      padding: 2px;

      span {
        background: #ebebeb;
        width: 65px;
        height: 65px;
        display: flex;
        place-content: center;
        place-items: center;
        border-radius: 50%;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .call-button {
      border-color: #5cdb5c;

      span {
        background: #5cdb5c;
        color: #fff;
        font-size: 24px;
      }
    }
  }
}
