.meeting-details-container {
    .header {
        padding: 12px 12px 0;

        h1 {
            color: #333;
            font-size: 22px;
            font-weight: 600;
            display: flex;
            place-content: space-between;
            place-items: flex-start;
            margin: 0;
        }

        .info-container {
            display: flex;
            gap: 10px;
        
            .info {
                display: flex;
                flex-direction: column;
                gap: 5px;
                flex: 1;
                margin: 8px 0;
                padding-left: 5px;
                border-left: 3px solid red;
            
                p {
                    font-size: 12px;
                    color: #666;
                    margin: 0;
                    white-space: normal;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    max-width: 275px;
                }
            }

            .quick-action {
                display: grid;
                margin: 8px 0;
                gap: 5px;

                button {
                    min-width: 28px;
                    height: 24px;
                    padding: 0;

                    &:nth-child(3):last-child {
                        grid-column: span 2;
                    }

                    &:nth-child(4) {
                        grid-column: 2;
                    }
                }
            }
        }
    }

    .stats {
        padding: 12px;

        p {
            position: relative;
            display: flex;
            place-items: center;
            gap: 5px;
            font-size: 12px;
            color: #666;
            padding: 5px 10px;
            margin: 0;
            background: #f2eded;
            border: 1px solid #ddd;
            border-radius: 5px;

            .copy-icon {
                position: absolute;
                right: 10px;
            }

            svg {
                font-size: 18px;
            }
        }

        .stats-1, .stats-2 {
            display: flex;
            gap: 10px;
            margin-top: 10px;
        }

        .stats-1 {
            p:nth-child(1) {
                flex: 0.4;
            }

            p:nth-child(2) {
                flex: 0.6;
            }
        }

        .stats-2 {
            p:nth-child(1) {
                flex: 0.75;
            }

            p:nth-child(2) {
                flex: 0.25;
            }
        }
    }

    .join-action {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0 12px 12px;
    }
}

.participants-detail-container {
    margin: 0 16px 85px;
    padding: 12px;
    overflow: auto;

    h2 {
        display: flex;
        place-items: center;
        gap: 5px;
        margin: 0;
        font-size: 22px;
        color: #333;
    }


    .table-container {
        width: 100%;
        overflow-x: auto;
        margin-top: 10px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
    }

    table th {
        background-color: #e9ecef;
        color: #333;
        min-width: 100px;
    }

    table th, table td {
        padding: 8px;
        text-align: left;
        font-size: 12px;
    }

    table thead {
        background-color: #f8f9fa;

        th:first-child {
            position: sticky;
            left: 0;
        }
    }

    table tbody tr {
        border-bottom: 1px solid #dee2e6;

        td:first-child {
            position: sticky;
            left: 0;
            display: flex;
            place-items: center;
            background: #fff;
            z-index: 1;
        }
    }

    table tbody tr:hover {
        background-color: #f1f3f5;
    }
}

.avatar-circle {
    width: 24px;
    height: 24px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-weight: bold;
    font-size: 12px;
}
  
.attende-pill, .role-pill {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 10px;
  color: #fff;
}
  
.attended, .moderator {
  background-color: #28a745;
}

.missed {
  background-color: #f7b924;
}

.participant {
    background-color: #007bff;
}

.timeline-bar-container {
    width: 100%;
    background-color: #e9ecef;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 8px;
  }
  
.timeline-bar {
    height: 8px;
    background-color: #007bff;
    position: absolute;
    top: 0;
    border-radius: 5px;
}

.participants-rating-feedback span svg {
    font-size: 16px;
}