.wa-template-dialog-container {
  .MuiPaper-root {
    margin: 20px;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
  }
}

.wa-template-header-container {
  display: flex;
  place-content: space-between;
  place-items: center;
  padding: 20px 24px;

  h2 {
    display: flex;
    place-items: center;
    gap: 5px;
    padding: 0;
    font-size: 16px;
  }

  span {
    display: flex;
    gap: 5px;

    button {
      font-size: 10px;
      display: flex;
      min-width: 50px;
      padding: 2px 0px;

      svg {
        font-size: 20px;
      }
    }
  }
}

.wa-template-content-container {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .edit-option-container {
    .MuiDialogContent-root {
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      gap: 25px;

      label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 8px;
      }

      input[type="file"] {
        margin-top: 8px;
        font-size: 14px;
      }

      input[type="text"] {
        font-size: 14px;
        padding: 8.5px;
      }
    }
  }

  .wa-template-smartphone {
    position: relative;
    width: 262px;
    height: 480px;
    margin: auto;
    border: 8px #808080 solid;
    border-top-width: 8px;
    border-bottom-width: 8px;
    border-radius: 32px;
    overflow: hidden;
    margin-bottom: 20px;

    .content {
      width: 100%;
      height: 100%;
      background-image: url("https://my-stg.saleassist.ai/bded4f44087fb4e89252b937d56edf23.png");
      background-size: cover;
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 8px;
      border-radius: 3px;
      box-sizing: border-box;

      .whatsapp-message-style {
        background-color: #dcf8c6;
        height: max-content;
        overflow: auto;
        max-height: 312px;
        border-radius: 8px;
        padding: 0 12px 12px;
      }
    }
  }

  .wa-template-smartphone:before {
    content: "";
    display: block;
    width: 11px;
    height: 10px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }
}
