.conversations-container {
  background: #fff;
  padding: 16px 5px 0;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .conversations-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 16px 16px;
    border-bottom: 1px solid #ccc;

    .custom-search {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 200px;
      width: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      border: 1px solid #ccc;
      border-radius: 50px;

      .search-input {
        width: calc(100% - 32px);
        padding: 10px 5px 10px 12.5px;
        font-size: 16px;
        line-height: 17px;
        border: none;
        border-radius: 50px;
        outline: none;
        box-sizing: border-box;

        &:focus {
          + .search-icon-wrapper .search-icon {
            color: #1976d2 !important;
          }
        }
      }

      .search-icon-wrapper {
        display: flex;
        place-items: center;
        place-content: center;
        width: 24px;
        height: 24px;
        font-size: 14px;
        border-radius: 50%;
        background: #e9e9e9;
        cursor: pointer;
      }
    }
  }

  .chat-notification {
    min-height: 48px;

    .MuiBadge-root {
      margin-left: 14px;
      margin-bottom: 4px;

      &:has(.MuiBadge-invisible) {
        display: none;
      }
    }
  }

  .conversations-container-body {
    overflow: auto;
    height: 100%;
    padding: 0 0;

    .conversation-wrapper {
      overflow: hidden;
      min-width: 240px;
      background: #fff;
      display: flex;
      flex-direction: row;
      height: 65px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      max-width: 100%;

      .avatar-container {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: #fff;
        font-weight: 700;
        position: relative;

        .avatar-text {
          margin: 0;
          font-size: 18px;
        }

        .avatar-status {
          width: 12px;
          height: 12px;
          bottom: 0;
          right: 1px;
          z-index: 1;
          box-sizing: border-box;
          position: absolute;
          border-radius: 100%;
        }
      }

      .conversation-unique-field {
        margin: 0;
        font-weight: 800;
        color: #6d6d6d;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        box-sizing: border-box;
        flex: 1;
      }

      .conversation-last-conversation-time {
        margin: 0;
        color: #aaa;
        font-size: 12px;
      }

      .conversation-status {
        margin: 0;
        font-size: 10px;
        color: #aaa;
        margin-top: 4px;
      }

      .unread-conversation {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        place-items: center;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        background-color: red;
        border-radius: 50%;
      }
    }

    .load-more-conversation-wrapper {
      width: 100%;
      color: #999;

      button {
        margin-top: 8px;
        width: 100%;
      }
    }

    .MuiTabPanel-root {
      padding: 24px 24px 85px;
    }
  }
}
