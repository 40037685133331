.send-message-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  z-index: 99;

  .uploaded-doc-preview {
    display: flex;
    font-size: large;
    background-color: #eee;
    padding: 14px;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    height: 50px;
    margin: 5px;
    border-radius: 6px;
    font-size: 14px;
    box-sizing: border-box;

    .remove-uploaded-doc-btn {
      width: 22px;
      height: 22px;
      display: flex;
      place-content: center;
      place-items: center;
      margin: 0 0 0 auto;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
    }
  }

  .send-message-wrapper {
    background: #fff;
    display: flex;
    align-items: center;
    gap: 1%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // box-shadow: inset 0.9px 0.9px 13px #d6d6d6, inset -0.9px -0.9px 13px #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 3px 4px;
    border-radius: 15px;
    padding: 10px;
    box-sizing: border-box;
    user-select: none;

    .action-button button {
      width: 22px;
      height: 22px;
      min-width: 20px;
      min-height: 20px;
      margin-left: 5px;
      font-size: 18px;
      background: #02307d;
    }

    .send-message-input {
      width: 100%;
      height: 32px !important;
      font-style: normal;
      resize: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      border: none;
      outline: none;
      padding: 0;
      overflow: hidden;
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.8);
      box-sizing: border-box;
      background: #fff;
    }

    .send-message-buttons-wrapper {
      position: relative;
      display: flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      margin-right: 5px;

      label {
        cursor: pointer;
      }

      svg {
        font-size: 18px;
      }

      button {
        font-size: 11px;
        padding: 2px 10px;
        min-width: auto;
        text-transform: capitalize;
        font-weight: 600;
      }

      .MuiLoadingButton-root:disabled {
        background: #1976d266;
        color: #fff;
      }

      .MuiLoadingButton-loading {
        background: #1976d266;
        color: transparent !important;

        .MuiLoadingButton-loadingIndicator {
          color: #fff;
        }
      }

      .emoji-icon {
        display: flex;
        svg {
          font-size: 22px;
        }
      }
    }
  }
}
